import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from './../components/Layout/Layout'
import { useAuth } from '../context/auth'

const HomePage = () => {
  const navigate = useNavigate()
  const [auth] = useAuth() // Assuming useAuth returns an array with auth state

  useEffect(() => {
    // Redirect admin users to the admin dashboard
    if (auth?.user && auth.user.role === 1) {
      // Check if the user is an admin
      navigate('/admin/dashboard')
    }
  }, [navigate, auth])

  return (
    <Layout title={'Home Page'}>
      <div className='d-flex align-items-center justify-content-center'>
        <div className='p-2 m-2 rounded-2'>
          <div className='h4 mt-4'>
            <div className='mb-2'>
              God desires all men to be saved and to come to the full knowledge
              of the truth.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HomePage
