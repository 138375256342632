import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import 'bootstrap-icons/font/bootstrap-icons.css'
import moment from 'moment'

const AdminEventRegisterList = () => {
  const navigate = useNavigate()
  const { eventId, id: _id } = useParams()
  const [eventUsers, setEventUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [totalPages, setTotalPages] = useState(1)
  const [eventName, setEventName] = useState('')
  const [totalCheckedInAdults, setTotalCheckedInAdults] = useState(0)
  const [totalCheckedInChildren, setTotalCheckedInChildren] = useState(0)
  const [totalAdults, setTotalAdults] = useState(0)
  const [totalChildren, setTotalChildren] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')
  const [isSearching, setIsSearching] = useState(false)

  useEffect(() => {
    const captureAndFetchData = async () => {
      try {
        await axios.post(
          `/api/v1/admin/event/capture-register-data/${eventId}/${_id}`
        )
        const response = await axios.get(
          `/api/v1/admin/event/get-register-data/${_id}?page=1&limit=30`
        )

        setEventUsers(response.data.data)
        setTotalPages(response.data.totalPages)
        setEventName(response.data.eventName)
        setTotalCheckedInAdults(response.data.totalCheckedInAdults)
        setTotalCheckedInChildren(response.data.totalCheckedInChildren)
        setTotalAdults(response.data.totalAdults)
        setTotalChildren(response.data.totalChildren)
      } catch (error) {
        setError('Failed to fetch event users')
      } finally {
        setLoading(false)
      }
    }

    captureAndFetchData()
  }, [_id])

  const observer = useRef()
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  const fetchData = async (page) => {
    setLoading(true)
    try {
      const response = await axios.get(
        `/api/v1/admin/event/get-register-data/${_id}`,
        {
          params: { page, limit: 30 },
        }
      )
      setEventUsers((prev) => [...prev, ...response.data.data])
      setHasMore(page < response.data.totalPages)
    } catch (error) {
      console.error('Error fetching event users:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (page > 1 && !isSearching) fetchData(page)
  }, [page, isSearching])

  const adultCheckInPercentage = totalAdults
    ? ((totalCheckedInAdults / totalAdults) * 100).toFixed(2)
    : 0

  const childrenCheckInPercentage = totalChildren
    ? ((totalCheckedInChildren / totalChildren) * 100).toFixed(2)
    : 0

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value
    setSearchQuery(searchTerm)

    if (searchTerm.length > 3) {
      setIsSearching(true)
      handleSearch(searchTerm)
    } else if (searchTerm.length === 0) {
      setIsSearching(false)
      fetchData(1)
    }
  }

  const handleSearch = async (searchTerm) => {
    setLoading(true)
    try {
      const response = await axios.get(
        `/api/v1/admin/event/search-register-data/${_id}`,
        {
          params: { search: searchTerm },
        }
      )
      setEventUsers(response.data.data)
    } catch (error) {
      console.error('Error searching event users:', error)
      setError('Failed to search event users')
    } finally {
      setLoading(false)
    }
  }

  const resetSearch = () => {
    setSearchQuery('')
    setIsSearching(false)
    setPage(1)
    setEventUsers([])
    fetchData(1)
  }

  const handleResetCheckin = async (userId) => {
    const confirmed = window.confirm('Confirm uncheck?')

    if (!confirmed) {
      return
    }

    try {
      const response = await axios.patch(
        `/api/v1/admin/event/reset-checkin/${userId}`
      )
      if (response.status === 200) {
        setEventUsers((prevUsers) =>
          prevUsers.map((user) =>
            user._id === userId
              ? { ...user, checkin: null, checkInBy: null, status: 0 }
              : user
          )
        )
        console.log('Check-in reset successfully')
      } else {
        console.error('Failed to reset check-in')
      }
    } catch (error) {
      console.error('Error resetting check-in:', error)
    }
  }

  return (
    <Layout title={'Admin Dashboard'}>
      <div className='pt-3 mx-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            <span className='fw-bold'> Adults:</span> {totalAdults} - Attend:{' '}
            {totalCheckedInAdults} ({adultCheckInPercentage}%) / Non-Attend:{' '}
            {totalAdults - totalCheckedInAdults} (
            {(100 - adultCheckInPercentage).toFixed(2)}
            %), <span className='fw-bold'>Children:</span> {totalChildren} -
            Attend: {totalCheckedInChildren} ({childrenCheckInPercentage}%) /
            Non-Attend: {totalChildren - totalCheckedInChildren} (
            {(100 - childrenCheckInPercentage).toFixed(2)}%)
          </div>
          <div className='text-end'>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={() => navigate('/admin/dashboard')}
            >
              Back
            </button>
          </div>
        </div>

        <div className='fw-bold fs-5 mt-2 mb-2' style={{ color: '#615336' }}>
          Event: {eventName}
        </div>

        <div
          style={{
            flex: 1,
            display: 'flex',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          <input
            type='text'
            className='form-control'
            placeholder='Search ID/Name/Phone'
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button
            type='button'
            className='btn btn-outline-secondary'
            onClick={resetSearch}
          >
            Clear
          </button>
        </div>

        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>ID</th>
              <th scope='col'>Name</th>
              <th scope='col'>Chi. Name</th>
              <th scope='col'>Phone</th>
              <th scope='col'>Locality</th>
              <th scope='col'>Language</th>
              <th scope='col'>QR Code</th>
              <th scope='col'>Status</th>
              <th scope='col'>Station</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading && eventUsers.length === 0 ? (
              <tr>
                <td colSpan='10' className='text-center'>
                  Loading...
                </td>
              </tr>
            ) : error ? (
              <tr>
                <td colSpan='10' className='text-center text-danger'>
                  {error}
                </td>
              </tr>
            ) : (
              eventUsers.map((user, index) => (
                <tr
                  key={user._id}
                  ref={index + 1 === eventUsers.length ? lastElementRef : null}
                >
                  <td>{user.eventRegisterId}</td>
                  <td>{user.name}</td>
                  <td>{user.chineseName}</td>
                  <td>{user.phone}</td>
                  <td>{user.locality}</td>
                  <td>{user.language}</td>
                  <td>{user.qrcode}</td>
                  <td>
                    {user.status === 1 && (
                      <i
                        className='bi bi-check-circle-fill'
                        style={{ color: 'green', marginRight: '10px' }}
                      ></i>
                    )}
                    {user.checkin
                      ? moment.utc(user.checkin).format('YYYY-MM-DD HH:mm:ss')
                      : ''}
                  </td>
                  <td>{user.checkInBy ? user.checkInBy.username : ''}</td>
                  <td className='text-end'>
                    {user.checkin && (
                      <button
                        className='btn btn-outline-warning btn-sm'
                        onClick={() => handleResetCheckin(user._id)}
                      >
                        <i className='bi bi-arrow-repeat'></i>
                      </button>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        {loading && eventUsers.length > 0 && (
          <div className='text-center mt-3'>
            <p>Loading more users...</p>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default AdminEventRegisterList
