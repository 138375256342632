import { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import axios from 'axios'
import Redirect from './../../pages/Redirect'
import { useAuth } from '../../context/auth'

export default function AdminRoute() {
  const [access, setAccess] = useState(false)
  const [auth, setAuth] = useAuth()

  useEffect(() => {
    const authCheck = async () => {
      try {
        const res = await axios.get('/api/v1/auth/admin-auth', {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        })
        if (res.data.access) {
          setAccess(true)
        } else {
          console.log('Access denied.')
          setAccess(false)
        }
      } catch (error) {
        console.error('Failed to verify admin access:', error)
        setAccess(false)
      }
    }
    if (auth?.token) authCheck()
  }, [auth?.token])

  return access ? <Outlet /> : <Redirect path='' />
}
